/*WebFont.load({
	google: {
		families: ['Marvel:400,700:latin']
	}
});*/


// placeholder
	(function ($) {
		$.support.placeholder = ('placeholder' in document.createElement('input'));
	})(jQuery);

	$(function () {
		if (!$.support.placeholder) {
			$('[placeholder]').focus(function () {
				if ($(this).val() == $(this).attr('placeholder')) $(this).val('');
			}).blur(function () {
				if ($(this).val() === '') $(this).val($(this).attr('placeholder'));
			}).blur();

			$('[placeholder]').parents('form').submit(function () {
				$(this).find('[placeholder]').each(function() {
					if ($(this).val() == $(this).attr('placeholder')) {
						$(this).val('');
					}
				});
			});
		}
	});

    // form validation
    /*
    $('.booking-form').validate({
        rules: {
            name: 'required',
            mail: 'required',
            datenschutz:'required'
        },
        messages: {
            name: 'Bitte geben Sie Ihren Namen ein.',
            mail: 'Keine gültige E-Mail Adresse.',
            datenschutz: 'Bitte akzeptieren Sie die Datenschutzerklärung.'
        }
    }); */


jQuery(document).ready(function($) {
	if ($("html").hasClass("lt-ie9")) {
		$(".columns div:first-child").css( "margin-left", "0" );
	}

	var vw = $(window).width();

	// headerOnScroll
	$(window).bind('scroll', function() {
		var st = $(window).scrollTop();

		if (st > 80 && vw > 1250) {
			$('.headerOnScroll').addClass('fixed');
		} else {
			$('.headerOnScroll').removeClass('fixed');
		}
	});

    // toggle menu
    $('.toggleMenu').on('click', function() {
        $('.navigation').slideToggle(300);
        return false;
    });

	// sub-menu
    if (vw > 1250 ) {
        $('.sub-menu').hover(function() {
            $('.sub-menu').toggleClass('hover');
            $('.sub-menu ul').fadeToggle(300);
            return false;
        });
    }

	// booking button
	if (vw > 1400 ) {
		$(window).bind('scroll', function() {
			var st = $(window).scrollTop();

			if (st > 300) {
				$('.booking-icon').css({'opacity':'1', 'width':'auto', 'padding':'0 20px'});
			} else {
				$('.booking-icon').css({'padding':'0', 'width':'0', 'opacity':'0'});
			}
		});

		$('.booking-icon').hover(function(){
			$(this).toggleClass('hover');
		});
	}

	// active menu point
	var loc = window.location.href;

    if(location.pathname == "/") {
        $('#index').addClass('active');
    }

	if(/index/.test(loc)) {
		$('#index').addClass('active');
	}

	if(/wohnungen-lindau/.test(loc)) {
		$('#lindau').addClass('active');
	}

	if(/wohnungen-wangen/.test(loc)) {
		$('#wangen').addClass('active');
	}

	if(/buchung/.test(loc)) {
		$('#booking').addClass('active');
		$('.booking-icon').css({'display':'none'});
	}
	
	if(/geschichte/.test(loc)) {
		$('#geschichte').addClass('active');
	}

	// bxslider
	$('.bxslider').bxSlider({
		mode: 'fade',
		auto: true,
		captions: true,
		pager: false,
		prevText: '',
		nextText: '',
		speed: 3000
	});

	// bxslider small
	$('.bxslider-small').bxSlider({
		mode: 'fade',
		auto: true,
		captions: true,
		pager: false,
		prevText: '',
		nextText: '',
		speed: 2000
	});

	$('.bx-wrapper').hover(function() {
		$('.bx-controls-direction').toggleClass('hover');
	});

	// room
	$('.icon-wrapper .icon').hover(function() {
		$(this).children('.icon-details').toggleClass('hover');
	});

	// images zoom
	$('.zoom').hover(function() {
		$(this).toggleClass('hover');
	});

	$('.image-back .button-white').hover(function() {
		$('.image-zoom').toggleClass('hover');
	});

	// accordion
	$('#accordion .button-more').on('click', function() {
		$(this).next('#accordion-wrapper').slideToggle(500);
		$(this).parent('#accordion').toggleClass('open');
	});

	//datepicker
	$('#arrival').datepicker({
		onClose: function( selectedDate ) {
			$('#departure').datepicker( 'option', 'minDate', selectedDate );
		}
	});
	$('#departure').datepicker( 'option', 'defaultDate', +3 );

	$('#departure').datepicker({
				onClose: function( selectedDate ) {
			$('#arrival').datepicker( 'option', 'maxDate', selectedDate );
		}
	});
	

	$.datepicker.setDefaults({
		dateFormat: 'd MM',
		dayNamesMin: [ 'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa' ],
		monthNames: ['Januar','Februar','März','April','Mai','Juni',
			'Juli','August','September','Oktober','November','Dezember'],
		monthNamesShort: ['Jan','Feb','Mär','Apr','Mai','Jun',
			'Jul','Aug','Sep','Okt','Nov','Dez'],
		firstDay: 1,
		numberOfMonths: 1
	});

    if (vw > 970 ) {
        $.datepicker.setDefaults({
            numberOfMonths: 2
        });
    }
    
    //lightbox
    lightbox.option({
      'resizeDuration': 200,
      'wrapAround': true
    })
});

	